<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="sendEmailDialog" max-width="500">
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
          Send HRDC Email To Client
        </v-toolbar>

        <div class="pa-4">Please confirm that you wish to send this email</div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalSendHrdcEmailToClient()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="sendEmailDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar color="#063058">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span v-if="hrdcData.isHrdcAdminRoles && !btnDisabled">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only"
                  color="white"
                  :loading="apiSubmit.isLoading"
                  @click="sendEmailDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-email-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ model.add.name }}</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <v-spacer></v-spacer>

      <div>
        <v-row
          class="d-flex justify-space-between"
          cols="12"
          lg="12"
          md="12"
          sm="12"
        >
          <v-col cols="6" style="border-right: 1px solid lightgrey">
            <v-card
              class="pa-2 ma-2"
              :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
              outlined
            >
              <!-- Email subject -->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-text-field
                  dense
                  filled
                  label="Email Subject"
                  v-model="formData.emailSubject"
                  :error-messages="errorField.emailSubject"
                ></v-text-field>
              </v-col>

              <!-- TO -->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-combobox
                  dense
                  filled
                  label="TO Recipient"
                  v-model="formData.toEmail"
                  :error-messages="errorField.toEmail"
                  :hide-selected="true"
                  multiple
                  small-chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                      <v-icon small @click="data.parent.selectItem(data.item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>

                  <template v-slot:append>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-information </v-icon>
                      </template>
                      Press <kbd>enter/tab</kbd> to insert multiple email
                    </v-tooltip>
                  </template></v-combobox
                >
              </v-col>

              <!-- CC -->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-combobox
                  dense
                  filled
                  label="CC Recipient"
                  v-model="formData.ccEmail"
                  :error-messages="errorField.ccEmail"
                  :hide-selected="true"
                  multiple
                  small-chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                      <v-icon small @click="data.parent.selectItem(data.item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>

                  <template v-slot:append>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-information </v-icon>
                      </template>
                      Press <kbd>enter/tab</kbd> to insert multiple email
                    </v-tooltip>
                  </template></v-combobox
                >
              </v-col>

              <!-- Remarks -->
              <v-col class="d-flex pb-0" cols="12" sm="12" md="12">
                <v-textarea
                  dense
                  filled
                  auto-grow
                  label="Remarks"
                  v-model="formData.remarks"
                  :error-messages="errorField.remarks"
                ></v-textarea>
              </v-col>

              <!-- Send Email Options -->
              <v-col class="d-flex pt-0" cols="12" sm="12" md="12">
                <v-radio-group v-model="formData.byPassEmailBool" column>
                  <template v-slot:label>
                    <div><b>Send Email Options:</b></div>
                  </template>
                  <v-radio
                    label="Send Email"
                    color="primary"
                    value="true"
                  ></v-radio>
                  <v-radio
                    label="Do Not Send Email"
                    color="error"
                    value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <!-- Auto Capture Grant Approval Email -->
              <v-col class="d-flex pt-0" cols="12" sm="12" md="12">
                <v-radio-group
                  v-model="formData.autoCaptureGrantApprovalEmail"
                  column
                >
                  <template v-slot:label>
                    <div><b>Auto Capture Grant Approval Email:</b></div>
                  </template>
                  <v-radio
                    label="Capture"
                    color="primary"
                    value="true"
                  ></v-radio>
                  <v-radio
                    label="Do Not Capture"
                    color="error"
                    value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card
              class="pa-2 ma-2"
              :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
              outlined
            >
              <!-- Confirmation Letter Company Name -->
              <v-col class="d-flex pb-0" cols="12" sm="12" md="12">
                <v-textarea
                  rows="2"
                  dense
                  filled
                  auto-grow
                  label="Confirmation Letter Event Address"
                  v-model.trim="formData.eventAddress"
                  :error-messages="errorField.eventAddress"
                  @input="autoEmbedData()"
                ></v-textarea>
              </v-col>

              <!-- Company Address -->
              <v-col class="d-flex py-0" cols="12" sm="12" md="12">
                <v-textarea
                  rows="4"
                  dense
                  filled
                  auto-grow
                  label="Company Address"
                  v-model.trim="formData.companyAddress"
                  :error-messages="errorField.companyAddress"
                  @input="autoEmbedData()"
                ></v-textarea>
              </v-col>

              <!-- Claim Amount -->
              <v-col class="d-flex py-0" cols="12" sm="12" md="12">
                <v-text-field
                  disabled
                  dense
                  filled
                  auto-grow
                  label="Claim Amount"
                  v-model="formData.totalPrice"
                  :error-messages="errorField.totalPrice"
                  @input="autoEmbedData()"
                ></v-text-field>
              </v-col>

              <!-- Event Date Range -->
              <v-col class="d-flex py-0" cols="12" sm="12" md="12">
                <v-col cols="6" class="pa-0">
                  <v-menu
                    ref="eventMenu"
                    v-model="formData.eventMenu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    disabled
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        prepend-inner-icon="mdi-calendar"
                        dense
                        filled
                        v-model="eventDate"
                        label="Event Date (YYYY-MM-DD)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errorField.eventDateRange"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="autoEmbedData()"
                      range
                      v-model="formData.eventDateRange"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="formData.eventMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.eventMenu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="py-0 pr-0">
                  <v-text-field
                    type="number"
                    dense
                    filled
                    label="JD14 Company Name & Address Font Size"
                    v-model="formData.companyAddressFontSize"
                    :error-messages="errorField.companyAddressFontSize"
                    @change="autoEmbedData()"
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-card>

            <hr />

            <v-card
              class="pa-2 ma-2"
              :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
              outlined
            >
              <!--Confirmation Letter-->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-col cols="9" class="pa-0">
                  <div v-cloak @dragover.prevent class="w-100">
                    <v-file-input
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-paperclip"
                      dense
                      filled
                      small-chips
                      label="Confirmation Letter"
                      accept=".pdf"
                      v-model="formData.confirmationLetterUrl"
                      :error-messages="errorField.confirmationLetterUrl"
                      :truncate-length="1000"
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col class="pt-0" cols="3">
                  <v-btn
                    x-large
                    color="#063058"
                    outlined
                    @click="previewAutoEmbedPdf('confirmationLetterUrl')"
                  >
                    Preview
                  </v-btn>
                </v-col>
              </v-col>

              <!--Send JD14 File-->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-col cols="9" class="pa-0">
                  <div v-cloak @dragover.prevent class="w-100">
                    <v-file-input
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-paperclip"
                      dense
                      filled
                      small-chips
                      label="JD14 Form"
                      accept=".pdf"
                      v-model="formData.sendJd14Url"
                      :error-messages="errorField.sendJd14Url"
                      :truncate-length="1000"
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col class="pt-0" cols="3">
                  <v-btn
                    x-large
                    color="#063058"
                    outlined
                    @click="previewAutoEmbedPdf('sendJd14Url')"
                  >
                    Preview
                  </v-btn>
                </v-col>
              </v-col>

              <!--Send T3 File-->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-col cols="9" class="pa-0">
                  <div v-cloak @dragover.prevent class="w-100">
                    <v-file-input
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-paperclip"
                      dense
                      filled
                      small-chips
                      label="T3 Form"
                      accept=".pdf"
                      v-model="formData.sendT3Url"
                      :error-messages="errorField.sendT3Url"
                      :truncate-length="1000"
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col class="pt-0" cols="3">
                  <v-btn
                    x-large
                    outlined
                    color="#063058"
                    @click="previewAutoEmbedPdf('sendT3Url')"
                  >
                    Preview
                  </v-btn>
                </v-col>
              </v-col>

              <!--Flyer (fake)-->
              <v-col class="d-flex" cols="9" sm="9" md="9">
                <div v-cloak class="w-100">
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    disabled
                    dense
                    filled
                    small-chips
                    label="Agenda"
                    v-model="formData.flyer"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>

              <!--Guide (fake)-->
              <v-col class="d-flex" cols="9" sm="9" md="9">
                <div v-cloak class="w-100">
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    disabled
                    dense
                    filled
                    small-chips
                    label="HRDC Claim Application Guideline"
                    v-model="formData.guide"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    eventDate() {
      return this.formData.eventDateRange.join(" - ");
    },
  },
  props: ["params"],
  data: () => ({
    apiSubmit: new Api(),
    apiGetHrdcEmailDetails: new Api(),
    apiAutoEmbedData: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    stages: null,
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    formData: {
      applicationNo: null,
      toEmail: [],
      ccEmail: [],
      emailSubject: null,
      eventDateRange: [],
      confirmationLetterUrl: null,
      sendJd14Url: null,
      sendT3Url: null,
      quotationMenu: false,
      eventMenu: false,
      eventAddress: null,
      eventSpeakers: [],
      remarks: null,
      byPassEmailBool: "true",
      companyAddress: null,
      companyAddressFontSize: 10,
      oriCompanyName: null,
      autoCaptureGrantApprovalEmail: "true",
    },
    errorField: {
      applicationNo: null,
      toEmail: null,
      ccEmail: null,
      emailSubject: null,
      eventDateRange: null,
      confirmationLetterUrl: null,
      sendJd14Url: null,
      sendT3Url: null,
      quotationMenu: null,
      eventMenu: null,
      companyAddressFontSize: null,
      autoCaptureGrantApprovalEmail: null,
    },
    loadingDialog: false,
    sendEmailDialog: false,
    btnDisabled: false,
    editMode: false,
  }),
  async created() {
    this.showLoadingDialog();
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Events Assignation.
    this.serviceDataAssignation(this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/email_client/create-email`,
        method: "post",
      },
      apiGetHrdcEmailDetails: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/email_client/get-email-details`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiAutoEmbedData: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/email_client/auto-embed-data`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[
        this.$store.state[this.service.key].data.stageId
      ];

    this.hrdcData.rolesAssignation(this.auth.roles);

    // Get email data.
    this.apiGetHrdcEmailDetails.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;

        // In Edit Mode.
        if (status == true) {
          data.companyAddress = response.companyAddress;
          this.formData = this.transformEmailDetails(data);

          // Disabled button, events do not have resend.
          this.btnDisabled = true;

          this.hideLoadingDialog();
        } else {
          // First time sending.
          const hrdcPicEmail = [
            ...response.companyPicDetail.map((item) => item.picEmail),
          ];

          // Include participants' email.
          const ApplicationEventParticipantObj =
            response.data.ApplicationEventParticipant;
          const ApplicationEventParticipant =
            ApplicationEventParticipantObj.map((item) => item.participantEmail);

          const toEmailArr = Array.from([
            ...hrdcPicEmail,
            ...ApplicationEventParticipant,
          ]);
          const ccEmailArr = [
            data.referByEmail,
            ...response.csData,
            process.env.VUE_APP_TAX_EVENT_EMAIL,
          ];

          this.formData.toEmail = toEmailArr.filter(
            (item, index, self) => item !== null && self.indexOf(item) == index
          );
          this.formData.ccEmail = ccEmailArr.filter(
            (item, index, self) => item !== null && self.indexOf(item) == index
          );
          this.formData.oriCompanyName = data.oriCompanyName;
          this.formData.emailSubject = `[HRDC] YYC ${
            this.name3
          } Training HRDC Grant Application - ${this.capitalizeFirstLetter(
            data.oriCompanyName
          )}`.toUpperCase();

          this.formData.companyAddress = response.companyAddress;
          this.formData.companyAddressFontSize = 10;
        }

        this.formData.flyer = {
          name: `YYC ${this.name1} Conference Course Content.pdf`.trim(),
        };

        this.formData.guide = {
          name: `YYC ${this.name2} CONFERENCE - HRDC Grant and Claim Application Procedure.pdf`.trim(),
        };

        // Append claim amount.
        this.formData.totalPrice =
          "RM " + Number(data.totalPrice).toLocaleString();

        // Assign event details.
        this.formData.eventTitle = data.Event.title;
        this.formData.eventSpeakers = data.Event.EventSpeaker.map(
          (item) => item.speakerName
        );
        this.formData.eventAddress = data.Event.venueAddress;
        this.formData.eventDateRange = [
          moment(data.Event.startDate, "YYYY-MM-DD HH:mm:ssZ").format(
            "YYYY-MM-DD"
          ),
          moment(data.Event.endDate, "YYYY-MM-DD HH:mm:ssZ").format(
            "YYYY-MM-DD"
          ),
        ];

        // Call embed attachment function.
        this.autoEmbedData();

        // If status is new, continue to the normal flow.
        if (status == "new") {
          this.hideLoadingDialog();
        }
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetHrdcEmailDetails.fetch();

    if (!this.hrdcData.isHrdcAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.checkFormAlreadyFilledGte(
      currentStageId,
      this.hrdcData.stageSlug.grantApprovalNoSubmitted
    );

    this.checkFormNotYetFilledLesser(
      currentStageId,
      this.hrdcData.stageSlug.applicationApproved,
      "Application haven't approve by Admin"
    );
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    autoEmbedData() {
      // Get the selected training start & end date.
      const [trainingStartDate, trainingEndDate] = this.formData.eventDateRange;

      this.apiAutoEmbedData.setParams({
        applicationUuid: this.$route.query._ausk,
        trainingStartDate,
        trainingEndDate,
        companyAddress: this.formData.companyAddress,
        companyAddressFontSize: this.formData.companyAddressFontSize,
        eventAddress: this.formData.eventAddress,
        confirmationLetterName: this.confirmationLetterName,
        jd14Name: this.jd14Name,
        t3Name: this.t3Name,
        hrdcType: this.hrdcType,
      });

      this.apiAutoEmbedData.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;

          if (!status) {
            this.$store.dispatch("showMessage", {
              message: data,
              messageType: "error",
              timeout: 2000,
            });

            this.formData.confirmationLetterUrl = null;
            this.formData.sendT3Url = null;
            this.formData.sendJd14Url = null;
            return;
          }

          if (status) {
            for (const file of response.data) {
              let blob = null;
              for (const item in file) {
                const fileItem = file[item];

                blob = new Blob([new Uint8Array(fileItem)]);

                switch (item) {
                  case "jd14":
                    this.formData.sendJd14Url = new File([blob], `JD14.pdf`, {
                      type: "application/pdf",
                    });
                    break;
                  case "t3":
                    this.formData.sendT3Url = new File([blob], `T3.pdf`, {
                      type: "application/pdf",
                    });
                    break;
                  case "confirmationLetter":
                    this.formData.confirmationLetterUrl = new File(
                      [blob],
                      `Confirmation Letter.pdf`,
                      {
                        type: "application/pdf",
                      }
                    );
                    break;
                }
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      });

      if (
        this.formData.companyAddress &&
        this.formData.oriCompanyName &&
        trainingStartDate &&
        trainingEndDate
      ) {
        this.apiAutoEmbedData.fetch();
      }
    },
    transformEmailDetails(data) {
      const emailData = {
        toEmail: [],
        ccEmail: [],
      };

      const {
        ApplicationAttachment,
        ApplicationEmail,
        ApplicationLog,
        ApplicationEventParticipant,
      } = data;

      const ApplicationLogSubscriptionDateScheduledObj = ApplicationLog.find(
        (item) =>
          item.stageId == this.hrdcData.stagePriority.subscriptionDateScheduled
      );

      const ApplicationLogSentEmailConfirmationObj = ApplicationLog.find(
        (item) =>
          item.stageId ==
          this.hrdcData.stagePriority.sentConfirmationEmailToClient
      );

      const ApplicationEmailObj = ApplicationEmail.find(
        (item) =>
          item.stageId ==
          this.hrdcData.stagePriority.sentConfirmationEmailToClient
      );

      emailData.remarks =
        ApplicationLogSentEmailConfirmationObj.remarks == "null"
          ? null
          : ApplicationLogSentEmailConfirmationObj.remarks;

      // Get Email Recipient (replace with what admin has updated).
      const hrdcPicEmail = [
        ...data.companyPicDetail.map((item) => item.picEmail),
      ];

      // Include participants' email.
      let _ApplicationEventParticipant = ApplicationEventParticipant.map(
        (item) => item.participantEmail
      );

      const toEmailArr = Array.from([
        ...hrdcPicEmail,
        ..._ApplicationEventParticipant,
      ]);
      const ccEmailArr = [
        data.referByEmail,
        ...data.csData,
        process.env.VUE_APP_TAX_EVENT_EMAIL,
      ];

      this.formData.toEmail = toEmailArr.filter(
        (item, index, self) => item !== null && self.indexOf(item) == index
      );

      this.formData.ccEmail = ccEmailArr.filter(
        (item, index, self) => item !== null && self.indexOf(item) == index
      );

      for (const email of this.formData.toEmail) {
        emailData.toEmail.push(email);
      }

      for (const email of this.formData.ccEmail) {
        emailData.ccEmail.push(email);
      }

      // Assign email subject.
      emailData.emailSubject = ApplicationEmailObj.emailSubject.toUpperCase();

      // Assign company address & font size.
      emailData.companyAddress = data.companyAddress;
      emailData.companyAddressFontSize = 10;

      //  Assign course start & end date.
      const courseStartDate =
        ApplicationLogSubscriptionDateScheduledObj.startDate;
      const courseEndDate = ApplicationLogSubscriptionDateScheduledObj.endDate;

      emailData.eventDateRange = [
        moment(courseStartDate, "YYYY-MM-DD HH:mm:ssZ").format("YYYY-MM-DD"),
        moment(courseEndDate, "YYYY-MM-DD HH:mm:ssZ").format("YYYY-MM-DD"),
      ];

      // Assign Send Email byPass value to true.
      emailData.byPassEmailBool = "true";

      // Assign auto capture grant approval email.
      emailData.autoCaptureGrantApprovalEmail = data.grantEmailHook
        ? "true"
        : "false";

      // Assign Company name to confirmation letter.
      emailData.oriCompanyName = data.oriCompanyName;

      // Assign attachment.
      for (const item of ApplicationAttachment) {
        // Create a Blob object from the data
        const blob = new Blob([new Uint8Array(item.dataArray)]);

        // Create the File object
        const file = new File([blob], item.fileName, {
          type: "application/pdf",
        });

        switch (item.type) {
          case "JD14":
            emailData.sendJd14Url = file;
            break;
          case "T3":
            emailData.sendT3Url = file;
            break;
          case "Confirmation Email":
            emailData.confirmationLetterUrl = file;
            break;
        }
      }

      return emailData;
    },
    previewAutoEmbedPdf(fileType) {
      if (!this.formData[fileType]) {
        this.$store.dispatch("showMessage", {
          message: "No Preview Available",
          messageType: "info",
        });
        return;
      }

      let url = null;
      let newWindow = null;
      switch (fileType) {
        default:
          url = URL.createObjectURL(this.formData[fileType]);
          newWindow = window.open(url, "_blank");
          break;
      }

      if (newWindow) {
        newWindow.onload = function () {
          // Revoke the object URL when the window is closed
          newWindow.addEventListener("beforeunload", function () {
            URL.revokeObjectURL(url);
          });
        };
      }
    },
    capitalizeFirstLetter(str) {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    //EOC
    modalSendHrdcEmailToClient() {
      this.sendEmailDialog = !this.sendEmailDialog;
      this.submit();
    },
    redirectBack() {
      let route = "HrdcEinvoicingApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state[this.service.key].data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    async submit() {
      this.showLoadingDialog();

      const fd = new FormData();
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("toEmail", this.formData.toEmail);
      fd.append("ccEmail", this.formData.ccEmail);
      fd.append("emailSubject", this.formData.emailSubject);
      fd.append("eventDateRange", this.formData.eventDateRange);
      fd.append("confirmationLetterUrl", this.formData.confirmationLetterUrl);
      fd.append("sendJd14Url", this.formData.sendJd14Url);
      fd.append("sendT3Url", this.formData.sendT3Url);
      fd.append("referById", this.auth.uuid);
      fd.append("referByName", this.auth.name);
      fd.append("referByEmail", this.auth.email);
      fd.append(
        "referByMobile",
        this.auth.mobile ?? this.hrdcData.yycMobileHotline
      );
      fd.append("remarks", this.formData.remarks);
      fd.append("editMode", this.editMode);
      fd.append("byPassEmailBool", this.formData.byPassEmailBool);
      fd.append("companyAddress", this.formData.companyAddress);
      fd.append(
        "autoCaptureGrantApprovalEmail",
        this.formData.autoCaptureGrantApprovalEmail
      );
      fd.append("agendaAtchName", this.agendaAtchName);
      fd.append("guidelineName", this.guidelineName);
      fd.append("eventSpeakers", this.formData.eventSpeakers);
      fd.append("eventTitle", this.formData.eventTitle);
      fd.append("hrdcType", this.hrdcType);

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.hideLoadingDialog();

          if (!response.status) {
            this.showErrorMessage(response);
            if (response.applicationNotFound) {
              this.$store.dispatch("showMessage", {
                message: response.data.applicationNo,
                messageType: "error",
                timeout: 2000,
              });
            }
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcEinvoicingApplication",
              query: {
                viewId: this.$store.state[this.service.key].data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
          this.hideLoadingDialog();
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>
